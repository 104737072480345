import React, { useLayoutEffect } from 'react';

export interface ICalendlyInlineWidgetProps {
    children?: any;
}

const CalendlyInlineWidget = (props: ICalendlyInlineWidgetProps) => {
    useLayoutEffect(() => {
        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;

        document.body.appendChild(script);
        return () => { };
    }, []);

    return <div className="calendly-inline-widget" style={{ minWidth: '320px', height: '700px' }} data-url="https://calendly.com/wernereffertz-century21/entretien-telephonique"></div>;
};

export default CalendlyInlineWidget;
