import React, { useRef, useState } from 'react';
// @ts-ignore
import VideoCover from 'react-video-cover';
import PlayIcon from '../components/play-icon';
import { IAirtableAttachment } from 'src/_model/airtable.model';


export interface IVideoComponentProps {
    attachment: IAirtableAttachment;
}

const VideoComponent = (props: IVideoComponentProps) => {

    let videoRef: any = useRef<any>(null);
    const [isShowPlayButton, setIsShowPlayButton] = useState<boolean>(true);

    const videoOptions = {
        poster: '',
        src: props.attachment.url,
        ref: (ref: any) => {
            videoRef = ref;
        },
        onClick: () => {
            if (videoRef && videoRef.paused) {
                videoRef.play();
                setIsShowPlayButton(false);
            } else if (videoRef) {
                videoRef.pause();
                setIsShowPlayButton(true);
            }
        },
        title: 'click to play/pause'
    };

    const playVideo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        videoRef.play();
        setIsShowPlayButton(false);
    };

    return (
        <>
            <div className="video video__content">
                {isShowPlayButton && (
                    <button className="btn btn-play" onClick={playVideo}>
                        <PlayIcon />
                    </button>
                )}
                <VideoCover
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                    }}
                    videoOptions={videoOptions}
                />
            </div>
        </>
    );
};

export default VideoComponent;
