import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import LazyLoad from 'react-lazyload';
import CalendlyInlineWidget from '../components/calendly-inline-widget';
import RenderConfigFE from '../../../src/components/render-config-fe';
import Video from '../../../src/components/video';
import { getConfigFrontEnd } from '../../../src/components/use-config-fe';
import { IAirtableAttachment } from '../../../src/_model/airtable.model';
import '../css/presentation.css';

const IndexPage = () => {
    const configPresentationVideo = getConfigFrontEnd({ name: 'PresentationVideo' }) as IAirtableAttachment;
    return (
        <Layout>
            <SEO title="Presentation" />

            <LazyLoad height={400} offset={100}>
                <section className="section section-widget">
                    <div className="container-fluid">
                        <RenderConfigFE className="title" name="PresentationTitle" type="html" />
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <Video attachment={configPresentationVideo} />
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <CalendlyInlineWidget />
                            </div>
                        </div>
                    </div>
                </section>
            </LazyLoad>
        </Layout>
    );
};

export default IndexPage;
